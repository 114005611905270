import { mapGetters } from "vuex"
import { format } from "date-fns";

import { dateToTimeZoneDate } from "@/utils/formatDateTime";

export default {
    computed: {
        ...mapGetters({
            zoneDetails: 'getterZoneDetails'
        }),
        getTodaysHoursOfOperation() {
            return this.zoneDetails?.mySpotSettings?.hoursOfOperation.filter((a) => a.name == format(dateToTimeZoneDate(new Date(), this.zoneDetails?.timezone), 'EEEE'))[0]?.is24HourOpen
        },
        urlSegment(){
            // To get the /g/ or /pb/g/ value and join into a single segment till booking id
            let segmentValue = window.location.pathname.split('/').slice(0, -1).join('/') + '/';
            // checking whether the hostnames are valid (dev and parking.com) or segment value is empty
            if((window.location.hostname == 'dev.parking.com' || window.location.hostname == 'parking.com' || window.location.hostname == 'qa.parking.com' || window.location.hostname == 'uat.parking.com' || window.location.hostname.includes('localhost')) || segmentValue == '')
            {
                segmentValue = process.env.VUE_APP_URL_SEGMENT;
            }
            //returning the segment value based on the hostname else returning /g/ by default to avoid collisions
            return (window.location.hostname == 'dev.parking.com' || window.location.hostname == 'parking.com' || window.location.hostname == 'qa.parking.com' || window.location.hostname == 'uat.parking.com' || window.location.hostname.includes('localhost')) ? segmentValue : '/g/'
        },
        segmentedBID(){
            let splitVal = window.location.pathname.split("/");
            let bookingId = splitVal[splitVal.length - 1];
            return bookingId;
        },

    },
    methods:{
        generateRandomToken() {
            return [...Array(60)].map(() => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('')
        },
        async getUserIpAddress() {
            const response = await fetch("https://ipapi.co/json/")
            const data = await response.json()
            return data.ip;
        },
        checkValidValue(value){
            return value!=undefined && value!=null && value != ''
        },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
              return "Windows Phone";
            }
      
            if (/android/i.test(userAgent)) {
              return "Android";
            }
      
            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              return "iOS";
            }
            return "unknown";
          },
          formatExitDateTime(time, val) {
            if (time !== null && time !== undefined && time != '') {
                let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
                let month = utcDate.split(",")[1].split(" ")[2]; // Jan
                let date = utcDate.split(",")[1].split(" ")[1]; // 27
                let year = utcDate.split(",")[1].split(" ")[3];
                let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
                let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
                let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
                formatHr = Number(hr) == 0 ? 12 : formatHr;

                formatHr = Number(formatHr) >= 10 ? Number(formatHr) : '0' + formatHr; //01
                let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
                let ampm = Number(hr) >= 12 ? "PM" : "AM";
                switch (val) {
                    case 'hour': {
                        return formatHr + amOrpm;
                    }
                    case 'mins': {
                        return min
                    }
                    case 'date': {
                        return month + " " + date + ", " + year + " " + formatHr + ":" + min + " " + ampm;
                    }
                    case 'expire': {
                        return formatHr + ":" + min + " " + ampm + ' on ' + month + " " + date
                    }
                }
            }
            //Jan 27, 01:59 PM
        },
    }
}